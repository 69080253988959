body {
	font-family: Play, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.page-wrapper {
	color: #444;
	font-family: inherit;
}

#printHeader,
#printFooter {
	display: none;
}

h1 {
	margin-top: 0rem;
	margin-bottom: 0.5rem;
	color: #002c6d;
	font-size: 2.25rem;
	line-height: 2.9rem;
	font-weight: 700;
	letter-spacing: 0.02rem;
}

h2 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #002c6d;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

h3 {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	color: #002c6d;
	font-size: 1.5rem;
	line-height: 1.975rem;
	font-weight: 700;
	letter-spacing: 0.02rem;
}

h4 {
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #002c6d;
	font-weight: 700;
	font-size: 1.25rem;
	line-height: 1.775rem;
	letter-spacing: 0.01rem;
}

h5 {
	margin-top: 1.375rem;
	margin-bottom: 0.75rem;
	color: #002c6d;
	font-size: 0.9375rem;
	line-height: 1.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
}

h6 {
	margin-top: 1.5rem;
	margin-bottom: 0.875rem;
	color: #002c6d;
	font-size: 0.8125rem;
	line-height: 1.25rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

p {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	color: #373a3d;
	line-height: 1.5rem;
}

body a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	font-family: Play, sans-serif;
	color: #005893;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 700;
	text-decoration: none;
}

body a:hover {
	color: #28313b;
	text-decoration: none;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.25rem;
	color: #373a3d;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 1.125rem;
}

li {
	margin-bottom: 0.25rem;
	color: #373a3d;
}

label {
	display: block;
	margin-bottom: 0.25rem;
	line-height: 1.25rem;
	font-weight: 700;
}

strong {
	font-weight: 700;
	letter-spacing: 0.02rem;
}

em {
	font-style: italic;
	letter-spacing: 0.02rem;
}

blockquote {
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1.5rem 2rem;
	border-left: 4px solid #0778d6;
	background-color: hsla(0, 0%, 87%, 0.25);
	font-size: 1.25rem;
	line-height: 1.8rem;
}

.button,
.primary,
.secondary,
.tertiary,
button[type="submit"] {
	display: inline-block;
	margin-top: 0.5rem;
	margin-right: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.75rem 2rem 0.75rem 1rem;
	background-color: #005893;
	background-image: url('../images/Arrow4_right_wht_25.svg');
	background-position: 92% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 1.25px;
	text-decoration: none;
	text-transform: uppercase;
}

.primary,
.secondary,
.tertiary,
button[type="submit"] {
	padding: 0.75rem 2rem 0.75rem 1rem !important;
	border: 0 none !important;
}

.button:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
button[type="submit"]:hover {
	background-color: #0778d6 !important;
	color: #fff;
	cursor: pointer;
}

.button.secondary,
.secondary,
button[type="submit"].secondary {
	background-color: #b20000;
	cursor: pointer;
}

.button.secondary:hover,
.secondary:hover,
button[type="submit"].secondary:hover {
	background-color: #f00a00 !important;
	cursor: pointer;
}

.button.tertiary,
.tertiary,
button[type="submit"].tertiary {
	padding: 0.35rem 2rem 0.35rem 1rem !important;
	background-color: #0778d6;
	font-size: 0.85rem !important;
	line-height: 1.5em;
}

.button.tertiary:hover,
.tertiary:hover,
button[type="submit"].tertiary:hover {
	background-color: #373a3d !important;
	cursor: pointer;
}

body a.primary,
body a.secondary,
body a.tertiary {
	color: #fff;
	cursor: pointer;
}

body a.primary:hover,
body a.secondary:hover,
body a.tertiary:hover {
	color: #fff;
	cursor: pointer;
}

.right-sidebar-column-2 .primary,
.right-sidebar-column-2 .secondary,
.right-sidebar-column-2 .tertiary,
.button.right-sidebar-button {
	font-size: 1rem;
}

.button.header-search-button {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 2rem;
	height: 100%;
	min-height: 2rem;
	margin-top: 0em;
	margin-right: 0px;
	margin-bottom: 0em;
	padding: 0em 1.25rem;
	float: right;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border: 2px solid transparent;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url('../images/search.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 0.8125rem;
	line-height: 1rem;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.3);
	cursor: pointer;
}

.nav-section {
	width: 100%;
	background-color: hsla(0, 0%, 100%, 0);
}

.nav-container {
	position: relative;
	max-width: 100%;
}

.nav-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 44px;
	max-width: 100px;
	margin-right: 0px;
	padding: 0.8rem 3.5rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-color: transparent;
	color: #525f94;
	font-size: 0.85rem;
	line-height: 1.375em;
	font-weight: 600;
	text-align: center;
}

.nav-link:hover {
	background-color: rgba(32, 108, 119, 0.25);
	cursor: pointer;
}

.nav-link.w--current {
	padding: 0.75rem 1.15rem 0.6rem;
	border-bottom: 2px solid #3aa1af;
	background-color: #f0f5f6;
	box-shadow: inset 1px 0 0 0 #fff, inset -1px 0 0 0 #fff;
	color: #00214b;
	font-size: 0.85rem;
	font-weight: 600;
}

.nav-link.w--current:hover {
	border-bottom-color: #3aa1af;
	background-color: #ebf2f3;
	box-shadow: none;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 640px;
	padding-right: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	border-left: 1px none hsla(0, 0%, 50%, 0.25);
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 300px;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-flex-shrink: 0;
	-ms-flex-negative: 0;
	flex-shrink: 0;
	border-bottom-style: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
}

.logo:hover {
	color: #fff;
	cursor: pointer;
}

.logo.w--current {
	width: 22%;
	min-width: 215px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.hidden-search-bar {
	display: none;
	height: 3.5rem;
	margin-bottom: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #ddd;
	opacity: 0;
}

.search-bar-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 75%;
	height: 2.65rem;
	max-width: 1600px;
	min-width: 991px;
	background-color: #fff;
}

.search-input-2 {
	height: 2.65rem;
}

.footer-section {
	position: relative;
	padding-top: 2rem;
	background-color: #005893;
	background-image: url('../images/subtle-carbon.png');
	background-position: 0px 0px;
	background-size: 9px;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	line-height: 1.375rem;
}

.row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	max-width: 1000px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.row.footer {
	max-width: 100%;
	padding-right: 0rem;
	padding-left: 0rem;
}

.column {
	width: 50%;
	padding-right: 0rem;
	padding-left: 0.5rem;
	float: left;
}

.column.signoff {
	padding-right: 0rem;
	padding-left: 0rem;
}

.logo-image {
	min-width: 100%;
	margin-right: 0.5rem;
	float: left;
}

.footer-signoff-section {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	background-color: #002c6d;
	font-size: 0.75rem;
	line-height: 1rem;
}

.copyright-text {
	display: inline-block;
	width: 100%;
	margin-right: 0.75rem;
	margin-left: 0rem;
	padding-right: 0.5rem;
	padding-left: 0rem;
	color: #c1c1c1;
	font-size: 0.8rem;
}

.footer-signoff-list {
	display: inline-block;
	width: 100%;
	margin-top: 0rem;
	margin-right: 0.25rem;
	margin-bottom: 0.25rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0rem;
	padding-right: 0.5rem;
	padding-left: 0rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	padding-left: 0;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #606060;
	font-size: 0.5rem;
	line-height: 0.5625rem;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #606060;
	cursor: pointer;
}

#GRIPFooterLogo {
	padding-top: 0 !important;
}

#GRIPFooterLogoImg {
	margin-top: -20px !important;
}

.footer-column-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 55%;
	margin-bottom: 1rem;
	padding-right: 1rem;
	clear: both;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-wrap.galesburg {
	width: 50%;
	padding-right: 0rem;
}

.footer-column-wrap.monmouth {
	width: 50%;
}

.footer-column-wrap.hours {
	width: 34.75%;
}

.footer-column-title {
	margin-top: 0.5em;
	color: #fff;
	font-size: 1.125rem;
	font-weight: 700;
}

.header-content-right-wrap {
	display: none;
	margin-right: 1em;
	float: right;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.header-contents-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 2px none #8ccdd6;
	background-color: #fff;
	background-image: url('../images/subtle-carbon.png');
	background-position: 0px 0px;
	background-size: 9px;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1em;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	height: 100%;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75rem;
	padding: 4px 10px;
	font-size: 0.875rem;
}

.form-field.right-sidebar-field {
	width: 100%;
	margin-top: 0.375em;
	margin-bottom: 1.25em;
	padding-right: 4px;
	padding-left: 8px;
}

.form-field.right-sidebar-field.miles-from {
	width: 52%;
}

.form-field.right-sidebar-field.zip-code {
	width: 45%;
}

.form-field.header-search-field {
	width: 2rem;
	height: 2.25rem;
	margin-bottom: 0em;
	padding: 0.5rem 1rem;
	float: left;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
	border: 0px none transparent;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
	background-image: -webkit-linear-gradient(206deg, #1b2f63, #3aa1af);
	background-image: linear-gradient(244deg, #1b2f63, #3aa1af);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 0.75em;
}

.form-field.header-search-field:focus {
	border: 0px none transparent;
	background-color: rgba(0, 0, 0, 0.3);
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item {
	margin-bottom: 0em;
	float: left;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
	letter-spacing: 0.01em;
}

.secondary-nav-list-item.first {
	border-left-style: none;
}

.secondary-nav-list-item-search {
	margin-right: 0rem;
	margin-bottom: 0rem;
	margin-left: 0rem;
	float: left;
	letter-spacing: 0.01rem;
}

.secondary-nav-list-link-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: #000000bf;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-icon-search {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #b20000;
}

.secondary-nav-list-item-search .secondary-nav-list-link-2:hover .secondary-nav-icon-search {
	background-color: #005893;
}

.secondary-nav-text-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #002c6d;
}

.secondary-nav-text-2:hover {
	color: #005893;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-navigation-column {
	padding-left: 0px;
}

.main-content-column.inside-right {
	padding-right: 0px;
	padding-left: 0px;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5rem;
	padding-left: 0px;
	box-shadow: none;
}

.left-nav-list-item {
	box-shadow: none;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.left-sidebar-custom h2,
.left-sidebar-custom h3,
.left-sidebar-custom h4,
.right-sidebar-column-2 h2,
.right-sidebar-column-2 h3,
.right-sidebar-column-2 h4,
.sidebar-section-title {
	margin-top: 0rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 1.775rem;
	color: #002c6d;
	font-weight: 700;
}

.sidebar-list {
	padding-left: 0em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.secondary-nav-icon {
	width: 1.375em;
	height: 1.375em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-text.indicator-text {
	position: relative;
	margin-left: 0.25rem;
	padding: 3px 5px;
	border-radius: 2px;
	background-color: rgba(33, 41, 49, 0.27);
	font-size: 0.5625rem;
	line-height: 1.25em;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 0.875rem;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	color: #fff;
	cursor: pointer;
}

.home-content-box-title {
	margin-top: 0rem;
	font-size: 1.375em;
	line-height: 1.5em;
}

.inside-page-header-content-wrap {
	margin-bottom: 1.5rem;
	padding-bottom: 0.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.header-menu-inside-wrap {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.intro_text_style,
.intro-text-style {
	font-size: 1.1875em;
	line-height: 1.5em;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 0.5em;
	padding-bottom: 1.5em;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.footer-link {
	color: #8ec8e4;
	font-size: 0.875rem;
}

.footer-link:hover {
	color: #bef2ff;
	cursor: pointer;
}

.footer-link.signoff {
	color: #ddd;
	font-size: 0.8rem;
	text-decoration: underline;
}

.footer-link.signoff:hover {
	color: #f50000;
	cursor: pointer;
}

.footer-link.phone {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 2.5rem;
	background-image: url('../images/icons-01.svg');
	background-position: 0px 50%;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	color: #ddd;
	line-height: 2rem;
	font-weight: 700;
}

.footer-link.phone:hover {
	color: #fff;
	text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
}

.footer-link.email {
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 1.75rem;
	background-image: url('../images/icons-02.svg');
	background-position: 0px 50%;
	background-size: 22px 22px;
	background-repeat: no-repeat;
	color: #ddd;
}

.footer-link.email:hover {
	color: #f50000;
	cursor: pointer;
}

.footer-link.email {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	padding-left: 2.5rem;
	background-image: url('../images/icons-02.svg');
	background-position: 0px 50%;
	background-size: 30px 30px;
	background-repeat: no-repeat;
	color: #ddd;
	line-height: 2rem;
	font-weight: 700;
}

.footer-link.email:hover {
	color: #fff;
	text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
}

.link {
	border-bottom: 1px solid rgba(84, 159, 196, 0.35);
	color: #277f8b;
	font-size: 1rem;
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: #549fc4;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.pagination-link {
	margin: 0.5em 0.25em;
	float: left;
	font-weight: 700;
	text-transform: uppercase;
}

.link.pagination-link.next {
	float: right;
}

.link.content-box-title-link {
	border-bottom-style: none;
	color: #0778d6;
	font-size: 3rem;
}

.link.content-box-title-link:hover {
	color: #1b2f63;
	cursor: pointer;
}

.link.breadcrumb-link {
	color: #373a3d;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
}

.pagination-middle-wrap {
	display: inline-block;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.pagination-middle-text {
	display: inline-block;
	margin-right: 0.5em;
	margin-left: 0.5em;
}

.pagination-middle-select {
	display: inline-block;
	width: auto;
	max-height: 2.25rem;
	margin-bottom: 0px;
	padding: 0px 0px 0px 2px;
}

.pagination-wrap {
	margin-bottom: 1.5em;
	padding-top: 0.75em;
	padding-bottom: 0.75em;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-size: 0.875em;
	line-height: 1.25em;
	text-align: center;
}

.md-doctor-form-reveal-wrap {
	display: none;
	overflow: hidden;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: rgba(0, 0, 0, 0.15);
}

.md-advanced-search-tooltip-popup {
	position: absolute;
	left: -1rem;
	bottom: 100%;
	display: none;
	width: 155px;
	margin-bottom: 0.5rem;
	padding: 0.75rem 1.125rem 1.125rem 0.875rem;
	border-radius: 3px;
	background-color: #28313b;
	box-shadow: 0 0 20px 5px rgba(40, 49, 59, 0.25);
	color: #fff;
	font-size: 0.8125em;
	line-height: 1.375em;
}

.md-patient-comments-reveal-more {
	overflow: hidden;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-view-buttons-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-view-button-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 48%;
	padding-top: 0.5em;
	padding-right: 0.25em;
	padding-bottom: 0.5em;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-style: solid;
	border-width: 2px;
	border-color: rgba(0, 0, 0, 0.5);
	border-radius: 20px;
	opacity: 0.55;
	-webkit-transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	transition: color 150ms ease, background-color 150ms ease, opacity 150ms ease, border-color 150ms ease, box-shadow 150ms ease, all 200ms ease;
	color: #000;
	font-weight: 700;
}

.loc-view-button-wrap:hover {
	border-color: rgba(0, 0, 0, 0.25);
	opacity: 1;
}

.loc-view-button-wrap.w--current {
	border-color: #000;
	opacity: 0.7;
}

.loc-view-button-wrap.w--current:hover {
	opacity: 1;
}

.loc-miles-from-zip-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.loc-list-section-title {
	margin-top: 0em;
}

.loc-select-type-form-wrap {
	position: relative;
}

.loc-type-form {
	position: relative;
}

.loc-type-form-checkbox {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	margin-bottom: 0.75em;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	line-height: 1.375em;
}

.loc-type-form-checkbox.select-all-wrap {
	font-weight: 700;
}

.loc-type-form-checkbox.sidebar-checkbox {
	width: 100%;
	margin-bottom: 0.5em;
}

.loc-type-form-checkbox.sidebar-checkbox.select-all {
	margin-top: 0.75em;
	font-weight: 600;
}

.loc-type-form-checkbox-box {
	position: relative;
	width: 1.5em;
	height: 1.5em;
	margin-top: 0px;
	margin-right: 0.5em;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
}

.loc-type-form-checkbox-box.select-all-checkbox {
	width: 1.4125em;
	height: 1.4125em;
	margin-right: 0.5em;
}

.loc-type-form-checkbox-label {
	position: relative;
	float: none;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.loc-type-form-checkbox-label.select-all-label {
	font-weight: 700;
	text-transform: uppercase;
}

.loc-type-form-checkboxes-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.75em;
	margin-bottom: 1.25em;
	padding-top: 1em;
	padding-bottom: 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.loc-type-form-checkbox-image {
	position: relative;
	display: block;
	width: 1.5em;
	height: 1.5em;
	margin-right: 0.5em;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-sidebar-search-reveal-button {
	position: relative;
	display: none;
	margin-bottom: 0em;
	padding: 0.75em 0.5em 0.75em 0.25em;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
	font-size: 1.125em;
	line-height: 1.25em;
	font-weight: 600;
	cursor: pointer;
}

.loc-sidebar-search-reveal-button:active {
	color: #777;
	cursor: pointer;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.right-sidebar-refine-search-button-text {
	margin-top: 0em;
	margin-bottom: 0em;
	margin-left: 0.25em;
	font-size: 1.125em;
	line-height: 1.25em;
}

.loc-refine-search-checkbox-wrap {
	padding-top: 0.5em;
	border-top: 1px solid rgba(84, 159, 196, 0.25);
}

.loc-view-button-text {
	margin-left: 0.25em;
}

.loc-view-button-image {
	position: relative;
	display: block;
	width: 1.125rem;
	height: 1.375rem;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 3%;
	margin-left: 3%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0.5rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px none hsla(0, 0%, 50%, 0.25);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom: 2px solid transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle.w--open {
	color: #fff;
	cursor: pointer;
}

.nav-drop-list {
	display: none;
	width: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	display: block;
	max-width: 1500px;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: transparent;
	color: #fff;
}

.nav-drop-list-link {
	display: block;
	width: 150px;
	margin-right: 0px;
	margin-bottom: 0.35rem;
	margin-left: 0px;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	border: 2px solid transparent;
	border-radius: 3px;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
}

.nav-drop-list-link:hover {
	background-color: transparent;
	color: #fff;
	text-shadow: 0 1px 31px #000;
}

.nav-drop-list-link.w--current {
	color: #fff;
	cursor: pointer;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 0.65rem 1.15rem;
	color: #596a97;
	font-size: 0.85rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
}

.nav-drop-toggle-link:hover {
	background-color: transparent;
	color: #00214b;
	cursor: pointer;
}

.nav-drop-toggle-link.w--current {
	border-right: 1px solid transparent;
	border-bottom: 1px solid transparent;
	border-left: 1px solid transparent;
	background-color: rgba(39, 127, 139, 0.15);
}

.header-container {
	display: none;
	width: 100%;
	height: 100%;
	min-width: 950px;
	margin-right: 155%;
	margin-left: 15%;
	padding-right: 1rem;
	padding-left: 1rem;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 1.5rem;
	line-height: 1.5em;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.secondary-header-item-cta-link {
	margin-left: 1rem;
	padding: 0.75em 3em 0.75em 1.5em;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	background-color: rgba(0, 0, 0, 0.25);
	background-image: url('../images/keyboard-right-arrow-button-1.svg');
	background-position: 85% 50%;
	background-size: 10px 10px;
	background-repeat: no-repeat;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.875rem;
	line-height: 1.25rem;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	cursor: pointer;
}

.header-search-wrap {
	display: none;
	margin-top: 1rem;
	margin-bottom: 1rem;
	float: right;
}

.header-search-form {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: hsla(0, 0%, 100%, 0.18);
}

.nav-drop-list-inside-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0.5rem;
	float: left;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.ordered-list.alpha-order {
	list-style-type: lower-alpha;
}

.ordered-list.roman-order {
	list-style-type: lower-roman;
}

.nav-drop-list-new {
	overflow: hidden;
}

.nav-drop-list-new.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #000;
	color: #fff;
}

.nav-drop-list-element {
	position: absolute;
	left: 0%;
	top: 100%;
	z-index: 3;
	display: none;
	overflow: hidden;
	width: 100%;
	min-width: 200px;
	margin: 0px;
	padding: 0.5rem;
	background-color: #000;
	box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.25);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #fff;
}

.nav-drop-background {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	display: none;
	background-color: rgba(0, 0, 0, 0.25);
}

.secondary-header-item-text {
	float: left;
}

.secondary-header-item-icon {
	width: 1em;
	height: 1em;
	margin-right: 0.5rem;
	float: left;
	opacity: 0.4;
}

.footer-container {
	display: block;
	width: 75%;
	max-width: 1600px;
	min-width: 925px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 0.5rem;
}

.div-block {
	display: none;
}

.nav-dropdown-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	background-color: #001b4b;
}

.patient-portal-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 130px;
	height: 31px;
	margin-top: 0.45rem;
	margin-bottom: 0.45rem;
	padding: 0.3rem 2rem 0.3rem 0.65rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border: 2px solid transparent;
	border-radius: 3px;
	background-color: #00214b;
	background-image: url('../images/patient_portal_secure_icon.svg');
	background-position: 92% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-align: center;
}

.patient-portal-button:hover {
	border: 2px solid #54c4c4;
	background-color: transparent;
	background-image: url('../images/patient_portal_secure_icon_hover-07.svg');
	background-position: 92% 50%;
	background-size: 12px;
	color: #fff;
}

.breadcrumb-current-page {
	color: #f50000;
	line-height: 0.6875rem;
}

.search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
}

.search-input {
	display: none;
}

.search-button {
	height: 100%;
	min-height: 40px;
	min-width: 40px;
	padding: 0.55rem 1.3rem;
	background-color: #b20000;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.search-button:hover {
	background-color: #005893;
	cursor: pointer;
}

.unis-header-section {
	position: static;
	left: 0px;
	top: 0px;
	right: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.search-hidden-dropdown {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	background-color: hsla(0, 0%, 100%, 0.2);
}

.search-form-block {
	display: none;
	margin-bottom: 0px;
	background-color: #f1f1f1;
	opacity: 0;
}

.search-wrap-new {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0px;
}

.new-header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 75%;
	max-width: 1600px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.mc-sport-logo-image {
	min-width: 350px;
	padding: 0.75rem 0.75rem 0.25rem;
	border-bottom: 3px none #d3d3d3;
	background-color: #fff;
}

.mc-sport-logo-link {
	width: 350px;
}

.mc-logo-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.google-translate-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
}

.html-embed-2 {
	padding-right: 2rem;
}

.address-para {
	color: #d1d1d1;
	line-height: 1.25rem;
}

.social-media-link-block {
	width: 32px;
	height: 32px;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	background-image: url('../images/social-media-icons-facebook.svg');
	background-position: 0px 0px;
	background-size: cover;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
}

.social-media-link-block:hover {
	background-image: url('../images/social-media-icons-facebook-hover.svg');
}

.social-media-link-block.last {
	margin-right: 0rem;
	background-image: url('../images/social-media-icons-youtube.svg');
}

.social-media-link-block.last:hover {
	background-image: url('../images/social-media-icons-youtube-hover.svg');
}

.social-media-link-block.twitter {
	background-image: url('../images/social-media-icons-twitter.svg');
}

.social-media-link-block.twitter:hover {
	background-image: url('../images/social-media-icons-twitter-hover.svg');
}

.social-media-link-block.linkedin {
	background-image: url('../images/social-media-icons-linkedin.svg');
}

.social-media-link-block.linkedin:hover {
	background-image: url('../images/social-media-icons-linkedin-hover.svg');
}

.social-media-link-block.flickr {
	background-image: url('../images/social-media-icons-flickr.svg');
}

.social-media-link-block.flickr:hover {
	background-image: url('../images/social-media-icons-flickr-hover.svg');
	background-position: 0px 0px;
	background-size: cover;
}

.main-section {
	background-color: #ddd;
	display: block;
}

.column-image-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.cb-para p {
	width: 100%;
	max-width: 80%;
	margin-top: 1rem;
	margin-bottom: 1rem;
	color: #373a3d;
	font-size: 1.2rem;
	line-height: 2rem;
}

.nav-link-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	padding: 1.2rem 0.75rem 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-bottom: 4px solid transparent;
	box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.08);
	color: #fff;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.nav-link-4:hover {
	margin-right: 0px;
	border-bottom-width: 4px;
	border-bottom-color: #69bbff;
	background-color: rgba(0, 0, 0, 0.05);
	box-shadow: none;
	color: #fff;
}

.nav-link-4.w--current {
	margin-right: 0px;
	padding-right: 1rem;
	padding-left: 1rem;
	border-bottom-style: none;
	background-color: #000;
	box-shadow: none;
	color: #fff;
	font-size: 1rem;
	text-transform: uppercase;
}

.nav-link-4.w--current:hover {
	background-color: #000;
	color: #fff;
	cursor: pointer;
}

.nav-drop-toggle-link-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 4px 0 0;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 4px solid transparent;
	box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.08);
	color: #fff;
	font-size: 1rem;
	line-height: 1.375em;
	font-weight: 400;
	text-align: center;
	letter-spacing: 0;
	text-transform: uppercase;
}

.has-dropdown .nav-drop-toggle-link-3 {
	padding-right: 1.5rem;
}

.nav-drop-wrap-3:hover .nav-drop-toggle-link-3 {
	border-bottom: 4px solid #69bbff;
	background-color: #11579f;
	color: #fff;
}

.nav-drop-wrap-3:hover .nav-drop-toggle-link-3.active-item {
	border-bottom: 5px solid #002c6d;
	background-color: #11579f;
	box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.08);
}

.nav-drop-toggle-link-3.active-item,
.nav-drop-toggle-link-3.w--current {
	border-bottom: 5px solid #002c6d;
	background-color: #11579f;
	box-shadow: inset -1px 0 0 0 rgba(0, 0, 0, 0.08);
}

.nav-drop-list-4 {
	display: block;
	opacity: 0;
	height: 0;
	-webkit-transition: opacity 300ms ease;
	transition: opacity 300ms ease;
}

.open .nav-drop-list-4 {
	left: 0px;
	top: 100%;
	height: auto;
	opacity: 1;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #002c6d;
	color: #fff;
}

.dropdown-list {
	display: none;
}

.open .dropdown-list {
	display: block;
}

.header-menu-inside-wrap-2 {
	display: block;
	width: 75%;
	max-width: 1600px;
	min-width: 961px;
	margin-right: auto;
	margin-left: auto;
}

.two-part-button-wrap-2 {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.dropdown-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.dropdown-list li {
	margin-bottom: 0;
}

.dropdown-list a,
.nav-drop-list-link-4 {
	display: block;
	padding: 0.5em 1.5em 0.5em 0.75rem;
	color: hsla(0, 0%, 100%, 0.75);
	font-size: 0.95rem;
	text-transform: none;
}

.dropdown-list a:hover,
.nav-drop-list-link-4:hover {
	background-color: rgba(7, 120, 214, 0.5);
	color: #fff;
	cursor: pointer;
}

.dropdown-list a.w--current,
.nav-drop-list-link-4.w--current {
	border-left-style: none;
	background-color: #0778d6;
	color: #fff;
}

.nav-menu-wrap-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 3.5rem;
	margin-top: 0px;
	float: left;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border-left: 1px none hsla(0, 0%, 50%, 0.25);
	margin-bottom: 0;
	padding-left: 0;
}

.header-menu-wrap-3 {
	border-top: 3px solid #d6d6d6;
	background-color: #0778d6;
	background-image: url('../images/subtle-carbon.png');
	background-size: 9px;
	box-shadow: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
}

.nav-drop-toggle-4 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 65%, 0.5);
}

.nav-drop-toggle-4:hover {
	box-shadow: none;
}

.nav-drop-toggle-4.w--open {
	color: #fff;
	cursor: pointer;
}

.nav-drop-toggle-4.w--open:hover {
	box-shadow: none;
}

.nav-drop-wrap-3 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	margin-bottom: 0;
	border-right: 1px none hsla(0, 0%, 50%, 0.25);
}

.nav-drop-icon-4 {
	margin-top: auto;
	margin-right: 1.5rem;
	margin-bottom: auto;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: #fff;
	font-size: 1.25rem;
}

.nav-container-3 {
	position: relative;
	display: block;
	height: 3.5rem;
	margin-right: auto;
	margin-left: auto;
}

.primary-button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.75rem 2rem 0.75rem 1rem;
	float: left;
	background-color: #005893;
	background-image: url('../images/Arrow4_right_wht_25.svg');
	background-position: 94% 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	text-align: center;
	letter-spacing: 1.25px;
	text-decoration: none;
	text-transform: uppercase;
}

.primary-button:hover {
	background-color: #b20000;
	color: #fff;
	cursor: pointer;
}

.content-section {
	display: block;
	max-width: 1170px;
	margin-right: auto;
	margin-left: auto;
}

.content-section.content-box-background {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: transparent;
	color: #fff;
}

.content-section.content-box-background._2 {
	display: inline-block;
	width: 100%;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #ddd;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	font-weight: 400;
}

.game-feature-div {
	display: block;
	width: 75%;
	max-width: 1600px;
	min-width: 992px;
	margin-right: auto;
	margin-left: auto;
}

.game-1-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.game-1-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #002c6d;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.game-1-image:hover {
	border-style: none;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

.game-1-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 5px solid #0778d6;
	background-color: #fff;
	color: #373a3d;
}

.game-2-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.game-2-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #002c6d;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.game-2-image:hover {
	background-size: 425px;
	background-attachment: scroll;
}

.game-2-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-right: 5px solid #0778d6;
	background-color: #fff;
	color: #373a3d;
}

.game-3-image {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #002c6d;
}

.game-3-text {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 70%;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-left: 5px solid #0778d6;
	background-color: hsla(0, 0%, 100%, 0.92);
}

.game-3-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.game-cb-box-wrap {
	display: block;
	width: 85%;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.google-embed-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding: 0.75rem 0rem 0.75rem 1.25rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.atari-pong-image {
	width: 275px;
	-webkit-transition: -webkit-transform 450ms ease;
	transition: -webkit-transform 450ms ease;
	transition: transform 450ms ease;
	transition: transform 450ms ease, -webkit-transform 450ms ease;
}

.atari-pong-image:hover {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.footer-quicklink {
	padding-bottom: 0.2rem;
	border-bottom: 2px solid hsla(0, 0%, 100%, 0.22);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1.75rem;
	font-weight: 400;
	text-transform: capitalize;
}

.footer-quicklink:hover {
	border-bottom-color: #f00a00;
	color: #fff;
}

.social-media-footer-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.quicklinks-list-div {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
}

.quicklinks-list-1 {
	width: 50%;
	margin-top: 0rem;
}

.quicklinks-list-2 {
	width: 50%;
	margin-top: 0rem;
}

.slide-caption-wrap {
	display: block;
	width: 60%;
	max-width: 600px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 2.5vw;
	border-radius: 3px;
	background-color: rgba(0, 44, 109, 0.88);
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
}

.rotator-image-section {
	display: block;
	background-color: #838db7;
}

.slider-indicators-2 {
	display: block;
	height: 48px;
	font-size: 0.7rem;
}

.slider-mask-2 {
	display: block;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
}

.button-4 {
	display: inline-block;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 0.5rem;
	padding: 0.4rem 1rem;
	background-color: #282f55;
	color: #fff;
	text-decoration: none;
}

.button-4:hover {
	background-color: rgba(0, 0, 0, 0.6);
	cursor: pointer;
}

.button-4.slider-button {
	margin-top: 0.5rem;
	margin-right: 0rem;
	padding-top: 0.75rem;
	padding-right: 2rem;
	padding-bottom: 0.75rem;
	background-color: #005893;
	background-image: url('../images/Arrow4_right_wht_25.svg');
	background-position: 94% 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 1.25px;
	text-transform: uppercase;
}

.button-4.slider-button:hover {
	background-color: #b20000;
	cursor: pointer;
}

.slide-image-container {
	position: relative;
	width: 100%;
	height: 100%;
	background-position: 0px 0px, 0px 0px;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.slider-header {
	margin-bottom: 0.75rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	background-color: transparent;
	color: #fff;
	font-size: 1.875rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-transform: uppercase;
}

.slider-arrow-link {
	top: auto;
	display: block;
	height: 60px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: hsla(0, 0%, 100%, 0.5);
	font-size: 2rem;
}

.slider-arrow-link:hover {
	color: #fff;
	cursor: pointer;
}

.slide-caption-outer-wrap {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 4rem;
	padding-left: 4rem;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url('../images/handle_1900x1267.jpg');
	background-position: 50% 50%;
	background-size: cover;
}

.slide-caption-outer-wrap {
	padding-right: 0rem;
	padding-left: 0rem;
	background-position: 0px 0px, 50% 50%;
	background-size: 9px, cover;
}

.slider-slide {
	position: relative;
	height: 100%;
	color: #fff;
	text-align: center;
	background-image: none;
}

.slide-paragraph p {
	color: #fff;
	font-size: 1.25rem;
	line-height: 1.65rem;
	text-align: left;
}

.nav-drop-icon-1 {
	margin-top: auto;
	margin-right: 1.5rem;
	margin-bottom: auto;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: #fff;
	font-size: 1.15rem;
}

.has-dropdown {
	position: relative;
}

.has-dropdown .nav-drop-toggle-link-3:after {
	content: "";
	border-color: #fff;
	border-width: 3px;
	border-style: solid;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	border-bottom-width: 0;
	border-left-width: 0;
	width: 7px;
	height: 7px;
	position: absolute;
	right: 11%;
	top: 40%;
	-webkit-transition: transform 350ms ease 0s;
	transition: transform 350ms ease 0s;
}

.has-dropdown:hover .nav-drop-toggle-link-3:after {
	-webkit-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	transform: rotate(135deg);
}

.nav-drop-icon-2 {
	margin-top: auto;
	margin-right: 1.5rem;
	margin-bottom: auto;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: #fff;
	font-size: 1.15rem;
}

.bowl-master-image {
	width: 275px;
	-webkit-transition: -webkit-transform 450ms ease;
	transition: -webkit-transform 450ms ease;
	transition: transform 450ms ease;
	transition: transform 450ms ease, -webkit-transform 450ms ease;
}

.bowl-master-image:hover {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.lane-master-image {
	width: 275px;
	-webkit-transition: -webkit-transform 450ms ease;
	transition: -webkit-transform 450ms ease;
	transition: transform 450ms ease;
	transition: transform 450ms ease, -webkit-transform 450ms ease;
}

.lane-master-image:hover {
	-webkit-transform: scale(1.05);
	-ms-transform: scale(1.05);
	transform: scale(1.05);
}

.nav-drop-icon-3 {
	margin-top: auto;
	margin-right: 1.5rem;
	margin-bottom: auto;
	-webkit-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	color: #fff;
	font-size: 1.15rem;
}

.authorized-tagline {
	color: #0778d6;
	font-size: 0.85rem;
	text-align: center;
	text-transform: uppercase;
}

.callout-banner {
	margin-bottom: 1rem;
	padding-top: 0.25rem;
	padding-bottom: 0.5rem;
	border: 5px solid #0778d6;
	background-color: #fff;
	color: #002c6d;
	font-size: 3.5rem;
	line-height: 5rem;
	text-align: center;
}

.game-4-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 1rem;
	padding-top: 3rem;
	padding-bottom: 3rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.fourth-cb-callout {
	width: 100%;
	padding-bottom: 1rem;
	color: #002c6d;
	font-size: 2.5rem;
	line-height: 3.5rem;
	font-weight: 700;
	text-align: center;
}

.game-list-wrapper ul:nth-child(1),
.games-list-1 {
	display: block;
	padding-right: 1.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.game-list-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 80%;
	padding-bottom: 1rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.game-list-wrapper ul:nth-child(2),
.games-list-2 {
	display: block;
	padding-right: 1.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.game-list-wrapper ul:nth-child(3),
.games-list-3 {
	display: block;
	padding-right: 1.25rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.secondary-button {
	display: inline-block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	padding: 0.75rem 2rem 0.75rem 1rem;
	float: left;
	background-color: #b20000;
	background-image: url('../images/Arrow4_right_wht_25.svg');
	background-position: 94% 50%;
	background-size: 14px 14px;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 200ms ease;
	transition: background-color 200ms ease, color 200ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 1rem;
	text-align: center;
	letter-spacing: 1.25px;
	text-decoration: none;
	text-transform: uppercase;
}

.secondary-button:hover {
	background-color: #eb0c00;
	color: #fff;
	cursor: pointer;
}

.game-list-wrapper ul a,
.game-link {
	color: #005893;
	cursor: pointer;
}

.game-list-wrapper ul a:hover,
.game-link:hover {
	color: #0778d6;
	cursor: pointer;
}

.image-slide {
	height: 55vh;
	min-height: 700px;
}

.left-nav-nested-list-link-2 {
	display: block;
	margin-bottom: 0.25rem;
	margin-left: 1rem;
	padding: 0.75em 1.5rem 0.75em 1rem;
	background-color: #fafafa;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	box-shadow: inset 3px 0 0 0 #c5c5c5;
	color: #002c6d;
	font-size: 0.95rem;
	line-height: 1.375em;
	font-weight: 700;
}

.left-nav-nested-list-link-2:hover {
	background-color: #fff;
	box-shadow: inset 3px 0 0 0 #f00a00;
	color: #0778d6;
	text-decoration: none;
}

.left-nav-nested-list-link-2.selected {
	background-color: #fff;
	box-shadow: inset 3px 0 0 0 #f00a00;
	color: #0778d6;
}

.left-nav-nested-list-link-2.great-grandchild {
	margin-left: 2rem;
	padding-left: 1rem;
	background-color: #fafafa;
	background-position: 19px 50%;
	box-shadow: inset 3px 0 0 0 #acacac;
	color: #002c6d;
	font-size: 0.875rem;
}

.left-nav-nested-list-link-2.great-grandchild:hover {
	background-color: #fff;
	box-shadow: inset 3px 0 0 0 #f00a00;
	color: #0778d6;
}

.left-nav-nested-list-link-2.great-grandchild.selected {
	background-color: #fff;
	box-shadow: inset 3px 0 0 0 #f50000;
	color: #0778d6;
	text-decoration: underline;
}

.left-nav-nested-list-link-2.great-grandchild.selected:hover {
	background-color: #fff;
	cursor: pointer;
}

.left-nav-nested-list-item-2 {
	box-shadow: none;
}

.section-wrap-2 {
	padding-top: 4rem;
	background-color: #f0f0f0;
}

.inside-page-content-wrap-inside-right {
	margin-right: 3%;
	margin-left: 3%;
}

.inside-page-content-wrap-inside-right.full-width .main-content-column {
	padding-right: 3.6%;
	padding-left: 3.6%;
}

.inside-content-background {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 2rem;
	padding-bottom: 3rem;
	background-color: #fff;
}

.main-content-column.w-col-12 {
	padding-left: 0;
	padding-right: 0;
}

.left-sidebar-custom a,
.right-sidebar-column-2 a,
.main-content-wrap a,
.text-link {
	border-bottom: 1px solid #ddd;
	color: #0778d6;
	font-size: 1rem;
}

.left-sidebar-custom a:hover,
.right-sidebar-column-2 a:hover,
.main-content-wrap a:hover,
.text-link:hover {
	color: #d11616;
	cursor: pointer;
}

.left-sidebar-custom a.w--current,
.right-sidebar-column-2 a.w--current,
.main-content-wrap a.w--current,
.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.text-link.breadcrumb-link {
	padding-bottom: 0.075rem;
	color: #373a3d;
	font-size: 0.6875rem;
	line-height: 0.6874rem;
}

.text-link.breadcrumb-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

.left-sidebar-custom h3 a,
.right-sidebar-column-2 h3 a,
.main-content-wrap h3 a,
.text-link.h3-link {
	border-bottom-color: hsla(0, 0%, 77%, 0.5);
	color: #0778d6;
	font-size: 1.5rem;
	line-height: 1.975rem;
}

.left-sidebar-custom h3 a:hover,
.right-sidebar-column-2 h3 a:hover,
.main-content-wrap h3 a:hover,
.text-link.h3-link:hover {
	color: #f00a00;
	cursor: pointer;
}

.left-sidebar-custom h6 a,
.right-sidebar-column-2 h6 a,
.main-content-wrap h6 a,
.text-link.h6-link {
	border-bottom-color: hsla(0, 0%, 77%, 0.5);
	color: #0778d6;
	font-size: 0.8125rem;
	line-height: 1.25rem;
}

.left-sidebar-custom h6 a:hover,
.right-sidebar-column-2 h6 a:hover,
.main-content-wrap h6 a:hover,
.text-link.h6-link:hover {
	color: #f00a00;
	cursor: pointer;
}

.left-sidebar-custom h5 a,
.right-sidebar-column-2 h5 a,
.main-content-wrap h5 a,
.text-link.h5-link {
	border-bottom-color: hsla(0, 0%, 77%, 0.5);
	color: #0778d6;
}

.left-sidebar-custom h5 a:hover,
.right-sidebar-column-2 h5 a:hover,
.main-content-wrap h5 a:hover,
.text-link.h5-link:hover {
	color: #f00a00;
	cursor: pointer;
}

.left-sidebar-custom h2 a,
.right-sidebar-column-2 h2 a,
.main-content-wrap h2 a,
.text-link.h2-link {
	border-bottom-color: hsla(0, 0%, 77%, 0.5);
	color: #0778d6;
	font-size: 1.875rem;
	line-height: 2.175rem;
}

.left-sidebar-custom h2 a:hover,
.right-sidebar-column-2 h2 a:hover,
.main-content-wrap h2 a:hover,
.text-link.h2-link:hover {
	color: #f00a00;
	cursor: pointer;
}

.left-sidebar-custom h1 a,
.right-sidebar-column-2 h1 a,
.main-content-wrap h1 a,
.text-link.h1-link {
	border-bottom-color: hsla(0, 0%, 77%, 0.5);
	color: #0778d6;
	font-size: 2.25rem;
	line-height: 2.9rem;
}

.left-sidebar-custom h1 a:hover,
.right-sidebar-column-2 h1 a:hover,
.main-content-wrap h1 a:hover,
.text-link.h1-link:hover {
	border-bottom-color: rgba(140, 205, 214, 0.5);
	color: #f00a00;
}

.left-sidebar-custom h4 a,
.right-sidebar-column-2 h4 a,
.main-content-wrap h4 a,
.text-link.h4-link {
	border-bottom-color: hsla(0, 0%, 77%, 0.5);
	color: #0778d6;
	font-size: 1.25rem;
	line-height: 1.775rem;
}

.left-sidebar-custom h4 a:hover,
.right-sidebar-column-2 h4 a:hover,
.main-content-wrap h4 a:hover,
.text-link.h4-link:hover {
	color: #f00a00;
	cursor: pointer;
}

.breadcrumb-current-page-2 {
	color: #d11616;
	line-height: 0.6875rem;
	font-weight: 700;
}

.left-nav-list-link-2 {
	display: block;
	margin-bottom: 0.25rem;
	padding: 0.75em 1.5rem 0.75em 1rem;
	border: 1px none transparent;
	background-color: #fafafa;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	box-shadow: inset 3px 0 0 0 #ddd;
	color: #002c6d;
	font-size: 1.15rem;
	font-weight: 700;
}

.left-nav-list-link-2:hover {
	background-color: #fff;
	box-shadow: inset 3px 0 0 0 #f00a00;
	color: #0778d6;
	text-decoration: none;
}

.left-nav-list-link-2.selected {
	background-color: #fff;
	box-shadow: inset 3px 0 0 0 #f50000;
	color: #0778d6;
	text-decoration: none;
}

.left-nav-list-link-2.section-title {
	margin-bottom: 0rem;
	background-color: #fff;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	box-shadow: inset 0 -3px 0 0 #f50000;
	color: #0778d6;
	font-size: 1.5rem;
	line-height: 1em;
	font-weight: 700;
	text-transform: uppercase;
}

.right-sidebar-column-2 .box,
.sidebar-section-wrap-2 {
	position: relative;
	margin-bottom: 2.441em;
	margin-left: 1em;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.5em;
}

.form-success-2 {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #298100;
	color: #fff;
}

.mobile-menu-styles-block-2 {
	position: relative;
	overflow: hidden;
	background-color: #28313b;
}

.inside-content-wrapper.full {
	display: block;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.form-error-2 {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #d11616;
	color: #fff;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000009;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="new-nav-drop-list"] {
	height: 0px;
}

html.w-mod-js *[data-ix="slider-upward-shift"] {
	opacity: 1;
}

html.w-mod-js *[data-ix="slide-text-load-top"] {
	opacity: 0;
	-webkit-transform: translate(0px, -100px);
	-ms-transform: translate(0px, -100px);
	transform: translate(0px, -100px);
}

html.w-mod-js *[data-ix="slide-text-load-top-2"] {
	opacity: 0;
	-webkit-transform: translate(0px, -25px);
	-ms-transform: translate(0px, -25px);
	transform: translate(0px, -25px);
}

html.w-mod-js *[data-ix="slide-text-load-top-3"] {
	opacity: 0;
	-webkit-transform: translate(0px, -25px);
	-ms-transform: translate(0px, -25px);
	transform: translate(0px, -25px);
}

html.w-mod-js *[data-ix="slide-text-load-bottom"] {
	opacity: 0;
	-webkit-transform: translate(0px, 22px);
	-ms-transform: translate(0px, 22px);
	transform: translate(0px, 22px);
}

html.w-mod-js *[data-ix="slide-left"] {
	opacity: 0;
	-webkit-transform: translate(125px, 0px);
	-ms-transform: translate(125px, 0px);
	transform: translate(125px, 0px);
}

html.w-mod-js *[data-ix="slide-left-2"] {
	opacity: 0;
	-webkit-transform: translate(125px, 0px);
	-ms-transform: translate(125px, 0px);
	transform: translate(125px, 0px);
}

html.w-mod-js *[data-ix="slide-right"] {
	opacity: 0;
	-webkit-transform: translate(-125px, 0px);
	-ms-transform: translate(-125px, 0px);
	transform: translate(-125px, 0px);
}

html.w-mod-js *[data-ix="slide-right-2"] {
	opacity: 0;
	-webkit-transform: translate(-125px, 0px);
	-ms-transform: translate(-125px, 0px);
	transform: translate(-125px, 0px);
}

html.w-mod-js *[data-ix="delay-transistions"] {
	opacity: 0;
}

.footer-phone-link {
	display: none;
}

.footer-phone-text:hover {
	color: #ddd !important;
	cursor: pointer;
}

.left-nav-header-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 60%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.secondary-nav-list-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item-2 {
	margin-right: 0.5rem;
	margin-bottom: 0rem;
	margin-left: 0.5rem;
	float: left;
	letter-spacing: 0.01rem;
}

.secondary-nav-list-item-2.first {
	margin-left: 0rem;
	border-left-style: none;
}

.secondary-nav-list-link-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: rgba(0, 0, 0, 0.75);
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-icon-2 {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #b20000;
}

.secondary-nav-text-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #002c6d;
}

.secondary-nav-text-2.indicator-text {
	position: relative;
	min-width: 2em;
	margin-right: 0rem;
	margin-left: 0.25rem;
	padding: 4px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	border-radius: 5px;
	background-color: #ddd;
	box-shadow: none;
	color: #22354b;
	font-size: 0.875em;
	line-height: 1.5em;
	text-align: center;
	letter-spacing: 0em;
}

.secondary-nav-list-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-left: 0rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	font-size: 1rem;
	line-height: 1.25rem;
}

.secondary-nav-list-item-3 {
	margin-right: 0.75rem;
	margin-bottom: 0rem;
	margin-left: 0.35rem;
	float: left;
	letter-spacing: 0.01rem;
}

.secondary-nav-list-link-search {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: #000000bf;
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-icon-3 {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #a52828;
}

.secondary-nav-text-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #354c66;
}

.secondary-nav-list-link-2 {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	color: rgba(0, 0, 0, 0.75);
	font-size: 0.75rem;
	font-weight: 600;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
}

.secondary-nav-icon-2 {
	width: 1.625rem;
	height: 1.625rem;
	margin-right: 0.375rem;
	padding: 6px;
	float: left;
	background-color: #a52828;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
}

.secondary-nav-text-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: #354c66;
}

.secondary-nav-list-link-2:hover .secondary-nav-icon-2 {
	background-color: #005893;
	cursor: pointer;
}

.secondary-nav-list-link-2:hover .secondary-nav-text-2 {
	color: #005893;
	cursor: pointer;
}

@media (max-width: 991px) {
	.footer-phone-link {
		display: inline-block;
	}

	.footer-phone-text {
		display: none;
	}

	.address-para,
	.phone-para,
	.email-para {
		margin-bottom: 0;
	}

	h1 {
		font-size: 2rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	h2 {
		font-size: 1.75rem;
	}

	li {
		padding-right: 0.25rem;
	}

	.button.header-search-button {
		width: 5rem;
		min-height: 4rem;
		font-size: 1.125rem;
	}

	.nav-container {
		height: 100%;
		max-width: 100%;
		min-height: 63px;
		min-width: 60px;
		margin-right: auto;
		margin-left: auto;
		float: right;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.nav-link {
		display: block;
		width: 100%;
		height: auto;
		min-width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		margin-left: 0px;
		padding: 0.75rem 1rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-color: transparent;
		box-shadow: none;
		color: #abbbe4;
		line-height: 1.375rem;
		font-weight: 600;
		text-align: left;
		text-transform: capitalize;
	}

	.nav-link:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: inset 4px 0 0 0 #8ccdd6;
	}

	.nav-link.w--current {
		height: auto;
		margin-left: 0px;
		background-color: rgba(39, 127, 139, 0.15);
		box-shadow: none;
		color: #fff;
	}

	.nav-link.w--current:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: none;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-menu-wrap {
		display: block;
		width: auto;
		min-width: 500px;
		padding-right: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		background-color: #00214b;
		text-align: left;
	}

	.logo {
		margin-top: 0.5rem;
		margin-bottom: 1.5rem;
		font-size: 1.375rem;
	}

	.logo.w--current {
		z-index: 1;
		display: block;
		width: 50%;
		height: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding-bottom: 0px;
		padding-left: 1.65rem;
	}

	.row.footer {
		padding-right: 0rem;
		padding-bottom: 0rem;
		padding-left: 0rem;
	}

	.column {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.column.signoff {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}

	.column.grip {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.logo-image {
		height: 91px;
	}

	.menu-button {
		display: block;
		width: 3rem;
		height: 100%;
		min-height: 100%;
		min-width: 3rem;
		margin-right: auto;
		margin-left: auto;
		padding: 0px 0rem;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button.w--open {
		background-color: transparent;
		cursor: pointer;
	}

	.footer-signoff-section {
		padding-top: 1rem;
		padding-bottom: 1.75rem;
		text-align: center;
	}

	.copyright-text {
		display: block;
		margin-bottom: 0.25rem;
		margin-left: 0rem;
		float: left;
		text-align: left;
	}

	.footer-signoff-list {
		text-align: left;
	}

	.footer-signoff-grip {
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		float: none;
	}

	.footer-column-wrap {
		max-width: 57%;
	}

	.footer-column-wrap.monmouth {
		width: 50%;
	}

	.footer-column-wrap.hours {
		width: auto;
	}

	.header-content-right-wrap {
		margin-right: 0.75em;
		padding-bottom: 0rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
	}

	.header-contents-wrap {
		display: block;
		width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.header-menu-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 10%;
		height: auto;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.right-sidebar-field {
		margin-bottom: 1.5em;
	}

	.form-field.right-sidebar-field.miles-from {
		width: 100%;
		margin-bottom: 0.25em;
	}

	.form-field.right-sidebar-field.zip-code {
		width: 100%;
	}

	.form-field.header-search-field {
		width: 100%;
		height: 4rem;
		margin-bottom: 0rem;
		padding-left: 3rem;
		-webkit-box-flex: 100%;
		-webkit-flex: 100%;
		-ms-flex: 100%;
		flex: 100%;
		font-size: 0.9375rem;
		line-height: 1.25em;
	}

	.form-field.header-search-field:focus {
		width: 100%;
	}

	.form-field.header-search-field::-webkit-input-placeholder {
		color: #fff;
		opacity: 1;
	}

	.form-field.header-search-field:-ms-input-placeholder {
		color: #fff;
		opacity: 1;
	}

	.form-field.header-search-field::placeholder {
		color: #fff;
		opacity: 1;
	}

	.secondary-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		border-left: 1px none hsla(0, 0%, 100%, 0.15);
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.secondary-nav-icon {
		position: relative;
		z-index: 2;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-text {
		margin-right: 0.25em;
		margin-left: 0.25em;
	}

	.secondary-nav-text.indicator-text {
		position: absolute;
		left: 1.25em;
		top: 50%;
		display: none;
		margin-right: 0em;
		margin-left: 0rem;
		float: none;
		font-size: 0.75em;
		line-height: 1.25em;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 2em;
		padding-left: 2em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 1.25em;
		font-weight: 600;
		cursor: pointer;
	}

	.secondary-nav-list-link-search {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.22rem;
		padding-right: 1.5rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-color: #00000040;
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.secondary-nav-list-link-search:hover {
		background-color: transparent;
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #fff;
	}

	.secondary-nav-icon-3 {
		width: 1.375rem;
		height: 1.375rem;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
		background-color: #0000;
	}

	.secondary-nav-text-3 {
		color: #ffffffbf;
		font-size: 0.75rem;
		line-height: 3.5rem;
		font-weight: 700;
	}

	.hidden-search-bar {
		display: none;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
		opacity: 0;
	}

	.search-bar-wrapper {
		width: 100%;
		max-width: 700px;
		min-width: 100%;
	}

	.search-input-2 {
		padding-left: 1.5rem !important;
	}

	.search-button {
		width: 10%;
		max-width: 50px;
		min-width: 92px;
		margin-right: 0rem;
	}

	.menu-button-icon {
		display: inline-block;
		width: 100%;
		height: 100%;
		min-height: 50px;
		min-width: 30px;
		margin-right: auto;
		margin-left: auto;
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		color: #277f8b;
		font-size: 2rem;
		line-height: 4rem;
	}

	.menu-button-icon:hover {
		color: #8ccdd6;
		cursor: pointer;
	}

	.menu-button-text {
		display: none;
		float: left;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.7rem;
		line-height: 4rem;
		letter-spacing: 0.02em;
	}

	.header-menu-inside-wrap {
		width: 100%;
		height: auto;
		max-width: 100%;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.link.content-box-title-link {
		font-size: 2.5rem;
	}

	.md-doctor-form-reveal-wrap {
		display: none;
	}

	.left-nav-reveal-button {
		display: block;
		margin-bottom: 1.25em;
		padding: 1em 50px;
		background-color: #5b636b;
		background-image: url('../images/menu_hamburger_stagger_wht.svg');
		background-position: 17px 50%;
		background-size: 19px;
		background-repeat: no-repeat;
		color: #b8c5d1;
		letter-spacing: 0.05em;
	}

	.left-nav-reveal-button:hover {
		color: #fff;
		cursor: pointer;
	}

	.left-nav-reveal-button:active {
		background-color: #50575e;
		cursor: pointer;
	}

	.loc-miles-from-zip-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.loc-type-form-checkbox {
		width: 33.33%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 33.33%;
		margin-bottom: 0.75em;
	}

	.loc-type-form-checkbox.sidebar-checkbox.select-all {
		width: 100%;
		margin-top: 0.75em;
	}

	.loc-type-form-checkboxes-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.loc-sidebar-search-reveal-button {
		position: relative;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.loc-refine-search-checkbox-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		padding-top: 0.75em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
		width: 100%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-drop-toggle:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 0 0 transparent;
	}

	.nav-drop-toggle-link {
		display: block;
		width: 90%;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1rem;
		float: left;
		border-bottom: 1px solid rgba(140, 205, 214, 0.1);
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
		color: #abbbe4;
		text-align: left;
	}

	.nav-drop-toggle-link:hover {
		background-color: rgba(131, 142, 153, 0.2);
		box-shadow: inset 4px 0 0 0 #8ccdd6;
		color: #fff;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('../images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		color: transparent;
		font-size: 0.875rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-right: 0.25em;
		background-image: url('../images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: 120px;
		max-width: 100%;
		min-width: auto;
		padding-top: 1rem;
		padding-right: 0rem;
		padding-left: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.secondary-header-item-wrap {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		font-size: 1.5rem;
	}

	.secondary-header-item-cta-link {
		margin-right: 1rem;
		margin-left: 1rem;
		font-size: 0.8125rem;
	}

	.header-search-wrap {
		width: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
		padding: 0rem;
		float: none;
	}

	.header-search-form {
		width: 100%;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.mobile-search-close-icon {
		position: relative;
		z-index: 2;
		display: none;
		width: 1.5em;
		height: 1.5em;
		margin: 0.25em;
		padding: 1px;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.mobile-search-active-bknd {
		position: absolute;
		left: 0px;
		top: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 1;
		display: none;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.1);
	}

	.secondary-header-item-icon {
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-container {
		width: 100%;
		max-width: none;
		min-width: 0;
		padding-right: 1.75rem;
		padding-bottom: 0rem;
		padding-left: 1.75rem;
	}

	.patient-portal-button {
		margin-top: 0.75rem;
	}

	.search-wrap {
		width: 100%;
	}

	.search-input {
		display: none;
		width: 100%;
		height: 46px;
		margin-bottom: 0px;
		padding-left: 2rem;
		float: left;
		background-color: #eee;
	}

	.search-input:focus {
		background-color: rgba(190, 242, 255, 0.2);
		width: 100%;
	}

	.search-input::-webkit-input-placeholder {
		color: #1b2f63;
		opacity: 1;
	}

	.search-input:-ms-input-placeholder {
		color: #1b2f63;
		opacity: 1;
	}

	.search-input::placeholder {
		color: #1b2f63;
		opacity: 1;
	}

	.search-button:hover {
		background-color: #838e99;
		cursor: pointer;
	}

	.search-wrap-new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 45px;
		margin-right: 2rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.new-header-container {
		width: 100%;
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.mc-logo-wrapper {
		width: 50%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.address-para {
		line-height: 1.25rem;
	}

	.social-media-link-block.facebook {
		margin-bottom: 0.75rem;
	}

	.main-section {
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
		background-repeat: repeat;
	}

	.cb-para p {
		max-width: 100%;
	}

	.nav-link-4 {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 0px;
		padding: 1em 1rem 1em 2rem;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		box-shadow: none;
		line-height: 1.375rem;
		text-align: left;
	}

	.nav-link-4:hover {
		border-bottom: 1px solid transparent;
		background-color: #2e5f6c;
		box-shadow: none;
	}

	.nav-link-4.w--current {
		margin-bottom: 0px;
		padding-left: 2rem;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		border-top-width: 3px;
		background-color: transparent;
		box-shadow: inset 5px 0 0 0 #48bdd7;
		color: #fff;
	}

	.nav-link-4.w--current:hover {
		background-color: transparent;
		cursor: pointer;
	}

	.nav-link-4.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.15);
		color: hsla(0, 0%, 100%, 0.55);
		cursor: pointer;
	}

	.nav-link-4.two-part-mobile {
		width: 80%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		float: left;
		color: #fff;
		text-decoration: underline;
		text-transform: uppercase;
	}

	.nav-link-4.two-part-mobile:hover {
		border-bottom-style: solid;
		border-bottom-color: transparent;
		background-color: #005893;
		box-shadow: none;
		text-decoration: none;
	}

	.nav-link-4.no-arrow {
		color: #fff;
		text-decoration: underline;
		text-transform: uppercase;
	}

	.nav-link-4.no-arrow:hover {
		background-color: #005893;
		box-shadow: none;
		text-decoration: none;
	}

	.nav-link-4.no-arrow.last {
		border-bottom-width: 0px;
	}

	.nav-link-4.no-arrow.home {
		padding-top: 1rem;
		padding-bottom: 1rem;
		text-decoration: underline;
	}

	.nav-link-4.no-arrow.home:hover {
		text-decoration: none;
	}

	.two-part-button-list-2 {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: none;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.menu-button-txt {
		margin-left: 0.125rem;
		float: left;
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 3.5rem;
		font-weight: 700;
		letter-spacing: 0.02em;
	}

	.nav-drop-toggle-link-3 {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.header-menu-inside-wrap-2 {
		width: 100%;
		max-width: 100%;
		min-width: auto;
		background-color: #0778d6;
	}

	.two-part-button-wrap-2 {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: none;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-top: 1px none rgba(73, 143, 163, 0.35);
	}

	.two-part-button-wrap-2.link-after-home {
		border-top-style: none;
	}

	.nav-menu-wrap-3 {
		display: block;
		width: 91%;
		padding-bottom: 100vh;
		background-color: #002c6d;
		background-image: none;
		background-size: auto;
		text-align: left;
	}

	.header-menu-wrap-3 {
		margin-top: 0px;
		border-top-style: none;
	}

	.nav-drop-toggle-4 {
		display: block;
	}

	.menu-button-4 {
		position: absolute;
		right: 0;
		height: 3.5rem;
		padding: 1px 1.75rem 0px 1.5rem;
		float: left;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 500ms ease;
		transition: all 500ms ease;
		color: rgba(0, 0, 0, 0.4);
		font-size: 1em;
		line-height: 3.5rem;
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button-4:hover {
		background-color: #005893;
		cursor: pointer;
	}

	.menu-button-4.w--open {
		background-color: transparent;
		cursor: pointer;
	}

	.two-part-button-toggle-2 {
		width: 20%;
		margin-bottom: 0px;
		padding-top: 1em;
		padding-bottom: 1em;
		float: right;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url('../images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: none;
		color: transparent;
		font-size: 0.75rem;
		line-height: 1.875rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle-2:hover {
		background-color: rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}

	.nav-drop-wrap-3 {
		width: 100%;
	}

	.menu-button-icon-3 {
		float: left;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		color: #fff;
		font-size: 2rem;
		line-height: 3.5rem;
		font-weight: 400;
	}

	.nav-container-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-flex-direction: row-reverse;
		-ms-flex-direction: row-reverse;
		flex-direction: row-reverse;
	}

	.content-section.content-box-background._2 {
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.game-feature-div {
		width: 100%;
		max-width: none;
		min-width: 0;
	}

	.game-1-wrapper {
		padding-top: 0rem;
	}

	.game-2-wrapper {
		padding-top: 0rem;
	}

	.game-2-image:hover {
		background-size: 375px;
	}

	.game-3-wrapper {
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.game-cb-box-wrap {
		width: 100%;
		padding-top: 1rem;
		padding-bottom: 1rem;
		float: right;
	}

	.google-embed-wrapper {
		width: 100%;
		padding-right: 1.25rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.slide-caption-wrap {
		width: 100%;
		max-width: 100%;
	}

	.button-4 {
		color: #d64949;
		cursor: pointer;
	}

	.button-4.slider-button {
		padding: 0.75rem 2rem 0.75rem 1rem;
	}

	.slide-paragraph p {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.lane-master-image {
		width: 225px;
		height: 303px;
	}

	.authorized-tagline {
		padding-bottom: 1rem;
	}

	.callout-banner {
		margin-right: 1rem;
		margin-left: 1rem;
		border-width: 4.5px;
	}

	.game-4-wrapper {
		padding-top: 0rem;
		padding-bottom: 2rem;
	}

	.fourth-cb-callout {
		padding-top: 2rem;
		font-size: 2rem;
		line-height: 3rem;
	}

	.game-list-wrapper ul:nth-child(1),
	.games-list-1 {
		font-size: 1rem;
	}

	.game-list-wrapper {
		width: 100%;
		padding-bottom: 0.5rem;
	}

	.game-list-wrapper ul a,
	.game-link {
		font-size: 1rem;
	}

	.image-slide {
		height: 610px;
		min-height: 0px;
	}

	.section-wrap-2 {
		padding-top: 0.25rem;
	}

	.inside-page-content-wrap-inside-right {
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 1.75rem;
		padding-left: 1.75rem;
	}

	.inside-page-content-wrap-inside-right.full-width {
		margin-right: 0%;
		margin-left: 0%;
	}

	.inside-content-background {
		max-width: 100%;
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}

	.inside-content-background.inside-full {
		padding-right: 1.75rem;
		padding-left: 1.75rem;
	}

	.right-sidebar-column-2 {
		margin-top: 2rem;
		padding-top: 2rem;
		padding-right: 0px;
		padding-left: 0px;
		border-top: 3px solid #ddd;
	}

	.right-sidebar-column-2 .box,
	.sidebar-section-wrap-2 {
		margin-bottom: 2rem;
		margin-left: 0em;
	}

	.inside-content-wrapper.full {
		max-width: 100%;
	}

	.two-part-button-list-3 {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #28313b;
	}

	.left-nav-header-column {
		width: 50%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.google-translate-div {
		position: relative;
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.secondary-nav-list-3 {
		margin-top: 0rem;
		margin-bottom: 0em;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.secondary-nav-list-item-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0rem;
		padding-right: 0rem;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
		border-left-width: 1px;
		border-left-color: rgba(0, 0, 0, 0.25);
	}

	.secondary-nav-list-link-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-top: 0.22rem;
		padding-right: 1.25rem;
		padding-left: 1rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
	}

	.secondary-nav-list-link-2:hover {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transform: none;
		-ms-transform: none;
		transform: none;
		color: #fff;
	}

	.secondary-nav-icon-2 {
		width: 1.375rem;
		height: 1.375rem;
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
		background-color: transparent;
	}

	.secondary-nav-text-2 {
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
		line-height: 3.5rem;
		font-weight: 700;
	}

	.secondary-nav-list-link-2:hover .secondary-nav-text-2 {
		color: hsla(0, 0%, 100%, 0.75);
		cursor: pointer;
	}

	.cart-counter-header {
		display: none;
	}

	.game-1-image {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.game-2-image {
		padding-right: 1rem;
		padding-left: 1rem;
		background-image: none;
	}

	.game-3-image {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

@media (max-width: 767px) {
	h1 {
		font-size: 1.6875rem;
		line-height: 1.3125em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 1.4375em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h3 {
		font-size: 1.3125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	h4 {
		font-size: 1.125rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		min-height: 3rem;
	}

	.nav-section {
		height: auto;
		min-height: auto;
	}

	.nav-menu-wrap {
		min-width: 450px;
	}

	.logo {
		margin-right: 10rem;
		padding-left: 1.5rem;
	}

	.logo.w--current {
		width: 15%;
		margin-top: 0em;
		margin-bottom: 0em;
		margin-left: 2rem;
		padding-top: 0.4rem;
		padding-bottom: 1.25rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-section {
		padding-top: 1rem;
		padding-bottom: 0rem;
	}

	.row {
		padding-bottom: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.row.footer {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column {
		width: 100%;
	}

	.column.signoff {
		width: 100%;
		text-align: center;
	}

	.column.grip {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.logo-image {
		margin-top: 1.25rem;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.menu-button {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-section {
		padding-bottom: 1.75rem;
	}

	.copyright-text {
		margin-right: 0rem;
		margin-left: 0rem;
		text-align: center;
	}

	.footer-signoff-list {
		text-align: center;
	}

	.footer-column-wrap {
		width: 50%;
		max-width: 100%;
		-webkit-box-ordinal-group: 0;
		-webkit-order: -1;
		-ms-flex-order: -1;
		order: -1;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.footer-column-wrap.monmouth {
		width: 50%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.footer-column-wrap.hours {
		padding-right: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
	}

	.footer-column-title {
		font-size: 0.975rem;
	}

	.header-contents-wrap {
		height: auto;
	}

	.form-field.header-search-field {
		height: 3.5rem;
	}

	.secondary-nav-list {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.secondary-nav-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.secondary-nav-list-link {
		padding-right: 1em;
		padding-left: 1em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		text-align: center;
	}

	.inside-page-header-content-wrap {
		padding-right: 0px;
		padding-left: 0px;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 0px;
		margin-left: 0px;
	}

	.menu-button-icon {
		line-height: 3rem;
	}

	.intro_text_style,
	.intro-text-style {
		font-size: 1.125em;
	}

	.footer-link.phone {
		font-size: 0.85rem;
	}

	.footer-link.email {
		font-size: 0.85rem;
	}

	.link.content-box-title-link {
		font-size: 2rem;
	}

	.pagination-middle-wrap {
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.loc-type-form-checkbox {
		width: 50%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 50%;
	}

	.header-container {
		height: 105px;
		padding-top: 0rem;
		padding-left: 0rem;
	}

	.secondary-header-item-wrap {
		font-size: 1rem;
	}

	.header-content-right-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: space-around;
		-ms-flex-line-pack: distribute;
		align-content: space-around;
	}

	.secondary-header-item-cta-link {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
		padding-top: 0.75em;
		padding-right: 2.35em;
		padding-left: 1.35em;
		background-position: 90% 50%;
		background-size: 8px 8px;
		font-size: 0.6875rem;
		line-height: 1rem;
	}

	.header-search-wrap {
		margin-top: 0rem;
	}

	.secondary-header-item-icon {
		margin-top: 0.25em;
		margin-bottom: 0.25em;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-container {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.patient-portal-button {
		width: 121px;
		padding-right: 1.5rem;
		background-position: 94% 50%;
		font-size: 0.84rem;
	}

	.search-input {
		display: block;
		width: auto;
		height: 100%;
		min-height: 42px;
		min-width: 85%;
		padding-left: 1.5rem;
		background-color: #eee;
		color: #1b2f63;
	}

	.search-input:focus {
		background-color: #bef2ff;
		background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.75), hsla(0, 0%, 100%, 0.75));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.75), hsla(0, 0%, 100%, 0.75));
	}

	.search-input::-webkit-input-placeholder {
		color: #1b2f63;
		opacity: 1;
	}

	.search-input:-ms-input-placeholder {
		color: #1b2f63;
		opacity: 1;
	}

	.search-input::placeholder {
		color: #1b2f63;
		opacity: 1;
	}

	.search-wrap-new {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 1.5rem;
	}

	.new-header-container {
		padding-top: 0rem;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}

	.mc-sport-logo-image {
		min-width: auto;
		padding-bottom: 0.25rem;
	}

	.mc-sport-logo-link {
		width: 250px;
		min-width: 50%;
	}

	.mc-logo-wrapper {
		width: 50%;
		padding-bottom: 0rem;
	}

	.address-para {
		line-height: 1.1rem;
	}

	.bottom-half-div {
		width: 100%;
	}

	.menu-button-4 {
		position: absolute;
		height: 3.5rem;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.nav-container-3 {
		height: 3.5rem;
	}

	.content-section.content-box-background {
		padding-bottom: 0rem;
	}

	.game-1-wrapper,
	.game-3-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.game-2-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.game-1-image,
	.game-2-image,
	.game-3-image {
		width: 100%;
		height: auto;
		min-width: 200px;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.game-1-text,
	.game-2-text,
	.game-3-text {
		width: 100%;
	}

	.google-embed-wrapper {
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.quicklinks-list-1 {
		width: 55%;
	}

	.slide-caption-wrap {
		width: 100%;
		max-width: 100%;
		padding: 2rem 2rem 3rem;
		background-color: transparent;
		background-image: none;
	}

	.rotator-image-section {
		position: relative;
		display: block;
		padding-top: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		background-color: transparent;
		-webkit-transition: padding 250ms ease;
		transition: padding 250ms ease;
	}

	.slider-mask-2 {
		height: auto;
		margin-right: 0px;
		margin-left: 0px;
		background-image: -webkit-linear-gradient(270deg, hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0.6));
		background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.6), hsla(0, 0%, 100%, 0.6));
		background-size: auto;
	}

	.button-4.slider-button {
		margin-top: 0.5rem;
		font-size: 1rem;
	}

	.slide-image-container {
		position: static;
		height: 350px;
	}

	.slide-image-container {
		background-position: 0px 0px, 50% 50%;
		background-size: 9px, cover;
		background-repeat: repeat, repeat;
	}

	.slider-header {
		color: #002c6d;
		font-weight: 400;
	}

	.slider-arrow-link {
		height: 45px;
		margin-bottom: 0.6rem;
		font-size: 1.5rem;
	}

	.slide-caption-outer-wrap {
		position: static;
		display: block;
		width: 100%;
		padding-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
		background-color: transparent;
	}

	.slide-caption-outer-wrap {
		background-image: none;
		background-position: 0px 0px;
		background-size: auto;
	}

	.slider-slide {
		height: auto;
		background-color: transparent;
	}

	.slider-arrow-3 {
		color: #0778d6;
		cursor: pointer;
	}

	.bowl-master-image {
		width: 185px;
		height: 249px;
		min-width: 185px;
	}

	.lane-master-image {
		width: 185px;
		height: 249px;
		min-width: 185px;
	}

	.authorized-tagline {
		font-size: 0.7rem;
		line-height: 1.15rem;
	}

	.callout-banner {
		border-width: 4px;
		font-size: 3.25rem;
		line-height: 4.5rem;
	}

	.fourth-cb-callout {
		padding-right: 2rem;
		padding-left: 2rem;
		line-height: 2.5rem;
	}

	.game-list-wrapper ul:nth-child(1),
	.games-list-1 {
		width: 180px;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-left: 1.75rem;
		font-size: 0.95rem;
	}

	.game-list-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.game-list-wrapper ul:nth-child(2),
	.games-list-2 {
		width: 180px;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-left: 1.75rem;
		font-size: 0.95rem;
	}

	.game-list-wrapper ul:nth-child(3),
	.games-list-3 {
		width: 180px;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-left: 1.75rem;
		font-size: 0.95rem;
	}

	.game-list-wrapper ul a,
	.game-link {
		font-size: 0.934rem;
	}

	.image-slide {
		height: auto;
		background-color: #fff;
	}

	.inside-page-content-wrap-inside-right {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.inside-content-background {
		padding-bottom: 3rem;
	}

	.inside-content-background.inside-full {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.right-sidebar-column-2 {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.right-sidebar-column-2 .box,
	.sidebar-section-wrap-2 {
		margin-left: 0rem;
	}

	.slide-paragraph p {
		color: #373a3d;
		text-align: center;
	}

	.quicklinks-list-div {
		display: block;
	}

	.quicklinks-list-1 {
		margin-bottom: 0;
	}

	.secondary-nav-list-3 {
		width: 50%;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.secondary-nav-list-item-3 {
		max-width: 125px;
		padding-right: 0rem;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.secondary-nav-list-item-3.search {
		background-color: rgba(0, 0, 0, 0.25);
	}

	.secondary-nav-list-item-3.search:hover {
		background-color: #0000;
	}

	.secondary-nav-list-link-search {
		padding-top: 2px;
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		background-color: #0000;
	}

	.secondary-nav-list-link-search:hover {
		background-color: transparent;
	}

	.secondary-nav-list-link-2 {
		padding-top: 2px;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.secondary-nav-text-2 {
		color: hsla(0, 0%, 100%, 0.75);
		font-size: 0.75rem;
	}
}

@media (max-width: 479px) {
	body {
		font-size: 0.9375rem;
		line-height: 1.5em;
	}

	h1 {
		font-size: 1.625rem;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	h2 {
		font-size: 1.4375rem;
		line-height: 1.5em;
	}

	.button.header-search-button {
		width: 78px;
		min-height: 3rem;
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.nav-link.two-part-mobile {
		width: 75%;
	}

	.nav-menu-wrap {
		width: 300px;
		min-width: auto;
	}

	.logo {
		margin: 0rem auto 1rem;
		padding-left: 0rem;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.logo.w--current {
		display: block;
		max-width: 100px;
		margin-top: 0rem;
		margin-bottom: 0rem;
		padding-top: 0.5rem;
		padding-right: 0px;
		padding-bottom: 0rem;
		float: left;
	}

	.row {
		padding-bottom: 0rem;
	}

	.row.footer {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.column {
		width: 100%;
		float: none;
	}

	.logo-image {
		display: block;
		max-width: 105px;
		float: none;
	}

	.menu-button {
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.footer-signoff-section {
		padding-bottom: 1.75rem;
	}

	.copyright-text {
		padding-right: 0rem;
		font-size: 0.754rem;
	}

	.footer-signoff-list {
		padding-bottom: 0.25rem;
	}

	.footer-column-wrap {
		padding-right: 0rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.footer-column-wrap.monmouth {
		width: 100%;
	}

	.footer-column-wrap.hours {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.header-content-right-wrap {
		display: none;
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.header-menu-wrap {
		width: 20%;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.form-field.header-search-field {
		height: 3rem;
		padding-right: 28%;
		padding-left: 1.25rem;
	}

	.secondary-nav-list {
		max-width: 75%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.secondary-nav-text {
		display: none;
	}

	.home-content-box-title {
		font-size: 1.25em;
	}

	.menu-button-icon {
		display: block;
		width: 100%;
		margin-top: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.menu-button-text {
		display: none;
	}

	.header-menu-inside-wrap {
		min-width: 60px;
	}

	.footer-link.phone {
		background-size: 30px 30px;
	}

	.footer-link.email {
		background-size: 30px 30px;
	}

	.link.content-box-title-link {
		font-size: 1.75rem;
		line-height: 2.15rem;
	}

	.pagination-middle-wrap {
		min-width: 100%;
		margin-top: 0.75em;
		padding-top: 0.75em;
		border-top: 1px solid rgba(84, 159, 196, 0.25);
	}

	.loc-type-form-checkbox {
		width: 100%;
	}

	.loc-type-form-checkbox.sidebar-checkbox {
		width: 100%;
	}

	.nav-drop-toggle-link {
		background-position: 95% 50%;
	}

	.two-part-button-toggle {
		width: 25%;
		line-height: 1.625rem;
	}

	.header-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 115px;
		padding-left: 1.25rem;
	}

	.secondary-header-item-text {
		display: none;
	}

	.footer-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.patient-portal-button.mobile {
		width: 136px;
		margin-top: 0rem;
		margin-right: 4rem;
		margin-bottom: 0rem;
		border-color: #54c4c4;
		background-color: transparent;
		background-image: url('../images/patient_portal_secure_icon_hover-07.svg');
		background-position: 91% 50%;
		background-size: 14px;
		color: #277f8b;
		line-height: 1.05rem;
		font-weight: 600;
	}

	.patient-portal-button.mobile:hover {
		border-color: transparent;
		background-color: #176a99;
		background-image: url('../images/patient_portal_secure_icon.svg');
		background-position: 91% 50%;
		background-size: 14px;
		color: #fff;
	}

	.search-input {
		min-height: 45px;
		min-width: 81%;
		background-color: #eee;
	}

	.new-header-container {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.mc-sport-logo-image {
		padding-top: 0.25rem;
	}

	.mc-sport-logo-link {
		width: 300px;
	}

	.mc-logo-wrapper {
		width: 100%;
		padding-top: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.google-translate-div {
		width: 100%;
	}

	.bottom-half-div {
		width: 100%;
	}

	.nav-link-4 {
		font-size: 1rem;
	}

	.nav-link-4.two-part-mobile {
		width: 75%;
	}

	.menu-button-txt {
		display: none;
	}

	.header-menu-wrap-3 {
		height: 3.25rem;
	}

	.menu-button-4 {
		width: 25%;
		height: 3.25rem;
		max-width: 65px;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.two-part-button-toggle-2 {
		width: 25%;
		height: 100%;
		padding-top: 1.25em;
		padding-bottom: 1.1em;
		line-height: 1.625rem;
	}

	.menu-button-icon-3 {
		display: block;
		margin-right: auto;
		margin-left: auto;
		float: none;
		line-height: 3.25rem;
	}

	.nav-container-3 {
		height: 3.25rem;
	}

	.content-section.content-box-background {
		padding-bottom: 0rem;
	}

	.game-1-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.game-1-image {
		width: 100%;
		padding: 1.5rem 2rem;
	}

	.game-1-text {
		width: 100%;
	}

	.game-2-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
	}

	.game-2-image {
		width: 100%;
		padding: 1.5rem 2rem;
		background-image: none;
	}

	.game-2-image:hover {
		background-size: 500px;
	}

	.game-2-text {
		width: 100%;
		border-left: 5px solid #0778d6;
		border-right-style: none;
	}

	.game-3-image {
		width: 100%;
		padding: 1.5rem 2rem;
	}

	.game-3-text {
		width: 100%;
	}

	.game-3-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.game-cb-box-wrap {
		width: 100%;
		float: none;
	}

	.google-embed-wrapper {
		padding-top: 1rem;
		padding-bottom: 0rem;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-caption-wrap {
		padding-right: 1.5rem;
		padding-left: 1.5rem;
	}

	.rotator-image-section {
		padding-top: 0px;
	}

	.slide-image-container {
		height: 200px;
	}

	.slider-header {
		font-size: 1.5rem;
		line-height: 1.75rem;
	}

	.slider-arrow-link {
		width: 60px;
	}

	.slide-paragraph p {
		margin-bottom: 0.5rem;
		font-size: 1.15rem;
		line-height: 1.65rem;
	}

	.bowl-master-image {
		max-width: 250px;
	}

	.lane-master-image {
		max-width: 250px;
	}

	.authorized-tagline {
		padding-bottom: 1rem;
	}

	.callout-banner {
		border-width: 3px;
		font-size: 1.75rem;
		line-height: 2.6rem;
		font-weight: 700;
	}

	.game-4-wrapper {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.fourth-cb-callout {
		font-size: 1.75rem;
		line-height: 2.15rem;
	}

	.inside-page-content-wrap-inside-right {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.inside-content-background.inside-full {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.quicklinks-list-div {
		display: flex;
	}

	.quicklinks-list-1 {
		margin-bottom: 0.75rem;
	}

	.left-nav-header-column {
		width: 100%;
	}

	.google-translate-div {
		width: 100%;
	}

	.secondary-nav-list-3 {
		width: 136px;
	}

	.secondary-nav-list-item-3 {
		max-width: 65px;
		width: 3.25rem;
		min-width: 3.25rem;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
	}

	.secondary-nav-list-link-search {
		padding-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.secondary-nav-icon-3 {
		margin-top: 0em;
		margin-right: 0rem;
	}

	.secondary-nav-text-3 {
		display: none;
	}

	.hidden-search-bar {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.search-button {
		max-width: 45px;
		min-width: 85px;
	}

	.secondary-nav-list-link-2 {
		padding-top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.secondary-nav-icon-2 {
		margin-top: 0em;
		margin-right: 0rem;
	}

	.secondary-nav-text-2 {
		display: none;
	}

	.html-embed-2 {
		padding-right: 0rem;
	}
}

html {
	font-size: 1rem;
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	-moz-osx-font-smoothing: grayscale;
}

sup {
	font-size: xx-small;
}

input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.w-slider-nav.w-round > div {
	background-color: rgba(255, 255, 255, 0.85);
	cursor: pointer;
}

.w-slider-dot.w-active {
	background-color: #f50000 !important;
	cursor: pointer;
}

.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	color: #ddd;
}

::-moz-selection {
	background-color: #e3e3e3;
	color: #000;
	opacity: 1;
}

::selection {
	background-color: #e3e3e3;
	color: #000;
	opacity: 1;
}

x.w-slider-nav > div {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div:hover {
	border: white 3px solid;
	background: black;
}

x.w-slider-nav > div.w-active {
	border: white 3px solid;
	background: white;
}

x.w-slider-nav > div.w-active:hover {
	border: white 3px solid;
	background: white;
}

.goog-te-gadget .goog-te-combo {
	padding: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.main-section,
	.section-wrap-2 {
		margin-top: -5px;
	}
}

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
		background-color: #002c6d;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}

	.mm-wrapper_opening .mm-menu_position-right.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(-80%, 0);
		-ms-transform: translate(-80%, 0);
		transform: translate(-80%, 0);
		-webkit-transform: translate3d(-80%, 0, 0);
		transform: translate3d(-80%, 0, 0);
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 35px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1rem 1rem 1rem 2rem;
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		text-transform: uppercase;
		float: none;
		box-shadow: none;
		font-weight: 500;
		line-height: 1.375rem;
		font-size: 1rem;
		text-decoration: underline;
	}

	.mm-menu li.active-item.mm-listitem,
	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: #005893;
		cursor: pointer;
		text-decoration: none;
	}

	.mm-menu a:hover {
		background-color: #005893;
		cursor: pointer;
		text-decoration: none;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #fff;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
		background-color: rgba(0, 0, 0, 0.15);
		border-bottom-color: #002c6d;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: #002c6d;
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: hsla(0, 0%, 100%, 0.75);
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 44%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid rgba(9, 24, 46, 0.5);
		padding: 0 10px 0 40px;
		height: 55px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		height: 55px;
		border-bottom: 0 none !important;
	}

	.mm-navbar__title {
		font-size: 1rem;
		line-height: 1.25rem;
		font-weight: 400;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
	}

	.mm-navbar:hover .mm-navbar__title {
		color: #fff;
		cursor: pointer;
	}
}

/*video content box*/
.homepage-featured-video .cbTitle,
.homepage-featured-video .moreVideos {
	display: none;
}

.homepage-featured-video {
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	padding-bottom: 39%;
	padding-top: 0;
	height: 0;
	background-color: #000;
}

.homepage-featured-video video,
.homepage-featured-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media (max-width: 767px) {
	.rotator-image-section {
		background-position: 50% 50%;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.homepage-featured-video {
		background-color: transparent;
		clear: both;
	}

	.homepage-featured-video video,
	.homepage-featured-video iframe {
		display: none;
	}
}

@media (max-width: 479px) {
	.homepage-featured-video {
		padding-bottom: 43.8%;
	}
}

/*inside pages*/
/*left column*/
.left-sidebar-custom {
	padding-right: 30px;
	font-size: 0.875rem;
	line-height: 1.5em;
}

.left-sidebar-custom .box {
	margin-bottom: 30px;
}

/*right column*/
.box.documents .content > table {
	margin-bottom: 0.75rem;
}

.box.documents .content td:first-of-type {
	padding-right: 10px;
	width: 35px;
}

.box .contentbox_item {
	margin-bottom: 0.75rem;
}

/*blockquotes*/
blockquote p {
	color: inherit;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
}

blockquote p:last-of-type {
	margin-bottom: 0;
}

/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider {
	height: 1px !important;
	margin-top: 1.5em !important;
	margin-bottom: 1.5em !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

hr:not(.fc-divider):not(.formBreak) {
	border-color: rgba(0, 0, 0, 0.15);
	border: 0px;
	color: rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid;
}

/*messages*/
#session-message-wrapper .success {
	background-color: #298100;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .error {
	background-color: #d11616;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.success *:last-child,
.error *:last-child {
	margin-bottom: 0;
}

/*images*/
img {
	height: auto;
}

td img {
	max-width: none;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}
}

/*tables*/
table.styled {
	width: 100%;
	margin: 20px 0 10px 0;
	background-color: transparent;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #002c6d;
	color: white;
}

table.styled th {
	font-weight: normal;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #0778d6;
	color: white;
}

table.styled th a {
	color: inherit;
	text-decoration: underline;
	border-bottom: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #f0f0f0;
}

table.styled.striped tr:nth-child(odd) {
	background: transparent;
}

@media (max-width: 39.9375em) {
	table.styled.tablesaw {
		border: 1px solid grey;
	}

	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0 none;
		border-bottom: 0 none;
	}

	.tablesaw-stack tbody {
		border-top: 1px solid grey;
	}

	.tablesaw-stack tbody tr {
		border-bottom: 0 none;
	}
}

/*forms*/
.cms_label,
.label,
label {
	font-weight: 400;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	font-size: 0.875rem;
	line-height: 1.42857143;
	padding: 4px 10px;
	border: 1px solid #333333;
	color: #333333;
}

textarea {
	resize: vertical;
}

/*module styling*/
/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: rgba(0, 0, 0, 0.15) !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

/* dates */
#events_module.cms_list .cms_date,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3 {
	color: #373a3d;
	line-height: 1.5rem;
	font-size: 1rem;
	font-family: Play, sans-serif;
	font-weight: 700;
}

/* ui form tags */
.ui-form-error {
	display: none;
}

.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: none;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	color: #002c6d !important;
	font-size: 1.2rem !important;
	line-height: 1.5rem;
	font-weight: 700 !important;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #cc0000;
	font-style: italic;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 4px 10px;
	font-size: 0.875em;
	line-height: 1.42857143;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #cccccc;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-note {
	line-height: 1rem;
	margin-top: 5px;
}

@media (max-width: 991px) {
	.ui-form-fields {
		padding: 0px;
	}

	.ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-input {
		width: 100%;
	}

	.ui-form-input input[type="text"],
	.ui-form-input input[type="tel"],
	.ui-form-input input[type="password"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="file"],
	.ui-form-input select,
	.ui-form-input textarea,
	.ui-form-fields .select2-container,
	.ui-form-fields .select2-container-multi,
	.ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-panel {
		padding: 0 !important;
	}

	.ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
		width: auto;
	}
}

/****************** Accounts ************************/
/***************************************************/
/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 420px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	padding-bottom: 7px;
	color: #002c6d;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}

	#account_addresses .address_section td a + br {
		display: block;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/****************** Events *********************/
/***********************************************/
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
	font-size: 1rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .event-category-label {
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0 0 10px;
	list-style-type: none;
}

/****************** News ***********************/
/***********************************************/
.box.archive {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 1px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module.cms_list .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#news_module.cms_list article time {
	font-weight: 600;
}

#news_module.cms_list article h2 {
	padding-top: 0;
}

.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

.archive_links {
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 991px) {
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}

	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}

/****************** Partners ********************/
/***********************************************/
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border-bottom-width: 0;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/******************* Posts *********************/
/***********************************************/
.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #005893;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

/* sidebar posts */
.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 12px 12px 8px !important;
	background-image: none;
}

.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.posts-blocks.posts-blocks-authors .posts-blocks-author,
.posts-blocks.posts-blocks-categories .posts-blocks-category,
.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 0.75em;
}

.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.posts-blocks-tags a {
	border-bottom-width: 0;
}

/***************** Resources *******************/
/***********************************************/
#moduleDocuments_module.cms_list .cms_item_description {
	margin-top: 7px;
}

.moduleDocuments-rss-feed-link {
	border-bottom-width: 0 !important;
}

.box.tools a[href^="?tag"] {
	border-bottom-width: 0;
}

/*************** Submission Forms ***************/
/************************************************/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_required em {
	display: none;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	content: "*";
	color: #cc0000;
	padding-left: 5px;
	font-style: italic;
	font-size: 1.2rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 1px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form .sigWrapper.current {
	height: 102px;
	width: 100%;
	max-width: 302px;
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
}

#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_required,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
	transform: translate(0px, -60%);
}

#submissionforms_module .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
	color: #373a3d;
}

#submissionforms_module .ui-form-label span {
	color: #373a3d;
	font-weight: 400;
}

#submissionforms_module.cms_form .required_information em {
	color: #cc0000;
}

#submissionforms_module .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 26%;
}

.module-forms.confirmation .step-title {
	font-family: Play, sans-serif;
	color: #002c6d;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
	margin-top: 20px;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_required,
	#submissionforms_module.cms_form .cms_label {
		padding-bottom: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding-left: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after,
	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #cc0000;
		padding-left: 5px;
	}

	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 37%;
		left: 4px;
	}
}

/******************* Videos ********************/
/***********************************************/
#video_module.cms_list .cms_video {
	padding-right: 20px;
}

#video_module.cms_list .cms_metadata2.cms_date {
	display: block;
	margin-bottom: 0.5rem;
	font-weight: 700;
}

#video_module.cms_entity .cms_metadata1.cms_date h3 {
	margin-top: 15px;
}

#video_module.cms_list .cms_metadata1.cms_title {
	padding-top: 0;
}

#video_module.cms_list .cms_list_item {
	padding-bottom: 1.5em;
	margin-bottom: 1.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#video_module.cms_list .cms_list_item:last-of-type {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0 none;
}

.right-sidebar-column-2 .box.categories .content a {
	display: inline-block;
	margin-bottom: 0.75em;
}

/****************** eCommerce **********************/
/***************************************************/
/*list view*/
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	font-size: 1.375rem;
	margin-bottom: 10px;
}

#products_module.responsive .item-image a,
#products_module.responsive .paginationView {
	border-bottom: 0 none;
}

#products_module.responsive .item-image a:hover,
#products_module.responsive .paginationView:hover {
	border-bottom: 0 none;
}

#products_module.responsive .item-old-price {
	color: #666666;
	font-weight: 400;
}

#products_module.responsive .item-sale-price {
	color: #b20000 !important;
	font-weight: 500;
}

#products_module.responsive .item-sale-price .sale-price-label {
	display: none;
}

#products_module.responsive .category-header-column,
#products_module.responsive .category-header-column {
	width: 100%;
}

/*details view*/
.product-details-info #product-container label {
	display: block;
	margin-top: 10px;
	margin-bottom: 3px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

.product-details-info #product-container br {
	display: none;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	width: 25%;
}

#products_module.responsive .add_to_cart_form .contentbox_item {
	margin-top: 15px;
}

#products_module.responsive .contentbox_item .accepted-formats {
	word-wrap: break-word;
}

#products_module.responsive .viewMoreAttributeOptions,
#products_module.responsive .hideMoreAttributeOptions {
	width: auto;
}

#products_module.responsive .affiliate_box .affiliate_url {
	margin-bottom: 3px;
	display: inline-block;
	width: auto;
}

#products_module.responsive .availability_box {
	margin-bottom: 20px;
}

#products_module.responsive .availability_box .available,
#products_module.responsive .availability_box .unavailable {
	display: block;
}

#products_module.responsive .availability_box .available {
	color: #2f6b3a;
	font-weight: 500;
}

#products_module.responsive .availability_box .unavailable {
	color: #b20000;
	font-weight: 400;
}

#products_module.responsive label em,
#products_module.responsive .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

#products_module.responsive .required-fields-information em {
	color: #cc0000;
	font-style: italic;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 400;
}

#products_module.responsive .tab-item-content .product-review {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 1.5em;
	padding-bottom: 1.5em;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#products_module.responsive .tab-item-content .product-review:last-of-type {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0 none;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin-left: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#products_module.responsive .add_to_cart_form .cart-estimate-button {
	width: 100%;
}

#products_module.responsive.cms_entity .tab-item-content .separator {
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	color: #002c6d;
	font-size: 1.875rem;
	line-height: 2.375rem;
	font-weight: 600;
	letter-spacing: 0.02rem;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info .add_to_cart_form .denomination-validation-message,
#products_module.responsive.cms_entity .add_to_cart_form .minimum_quantity_label,
#products_module.responsive.cms_entity .add_to_cart_form .maximum_quantity_label {
	color: #e00000;
}

@media (min-width: 481px) and (max-width: 720px) {
	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		width: 47%;
		margin-bottom: 5px;
		margin-right: 2%;
	}
}

@media (max-width: 720px) {
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}

	#products_module.responsive.cms_form .ui-form-buttons {
		text-align: left;
	}
}

/*checkout views*/
#s-customer #s-sign-in .ui-form-label {
	height: auto;
	padding-bottom: 0;
}

#s-customer #s-sign-in .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

#s-checkout .ui-form-label label em,
.ui-form-field-subtitle .ui-form-label span em {
	color: #cc0000;
	font-style: italic;
}

.ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: normal;
	color: #373a3d;
}

#s-cart .w-col.s-summary #s-estimate-shipping button {
	margin: 0;
	white-space: normal;
}

#ui-ship-to-addresses {
	max-height: none;
}

#s-checkout fieldset {
	border: 0 none;
}

@media (max-width: 991px) {
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-label,
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-form {
		float: none;
	}
}

@media (min-width: 767px) {
	#s-checkout fieldset .ui-form-text > input#ShippingPhone[type="text"],
	#s-checkout fieldset .ui-form-text > input#BillingPhone[type="text"] {
		width: 189px;
	}
}

@media (max-width: 767px) {
	#s-checkout .ui-form-buttons.ui-form-field-place-order {
		padding-left: 0;
	}
}

/*account order history*/
@media (max-width: 767px) {
	#account_module.entity #account_orderhistory .order_section td {
		display: block;
		width: 100%;
		padding-bottom: 15px;
		text-align: left !important;
	}
}

/*module content boxes*/
/* events */
#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 1.5em;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
	padding-top: 2px;
	font-weight: 700;
	color: #444;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

#events_module.homepage_contentbox .event_list table tr td.title a {
	font-size: 1.125rem;
	line-height: 1.625rem;
	color: #0778d6;
	font-weight: 700;
	text-decoration: underline;
}

#events_module.homepage_contentbox .event_list table tr td.title a:hover {
	color: #1b2f63;
	cursor: pointer;
}

#events_module.homepage_contentbox .cms_description {
	color: #373a3d;
	font-size: 1rem;
}

#events_module.homepage_contentbox .cms_description p {
	margin-bottom: 0;
}

#events_module.homepage_contentbox .footer {
	display: none;
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox .event_list table tr td.date,
	#events_module.homepage_contentbox .event_list table tr td.title {
		display: block;
		width: 100%;
	}
}

/* news  */
#news_module.homepage_contentbox .cms_metadata1.cms_title h3 {
	margin-top: 0;
	margin-bottom: 0;
}

#news_module.homepage_contentbox .cms_metadata1.cms_title h3 a {
	font-size: 1.125rem;
	line-height: 1.625rem;
	color: #0778d6;
	font-weight: 700;
	text-decoration: underline;
}

#news_module.homepage_contentbox .cms_metadata1.cms_title h3 a:hover {
	color: #1b2f63;
	cursor: pointer;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1rem;
	line-height: 1.5em;
	font-weight: 700;
	color: #444;
	margin-top: 5px;
}

#news_module.homepage_contentbox .cms_description p {
	margin-top: 0;
	font-size: 1rem;
	line-height: 1.5em;
}

#news_module.homepage_contentbox.displaymode_list > a {
	display: none;
}

/* partners */
#partners_module.homepage_contentbox.displaymode_list {
	margin-top: 15px;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	margin-bottom: 1rem;
}

#partners_module.homepage_contentbox.displaymode_list .cms_title a {
	font-size: 1.125rem;
	line-height: 1.625rem;
	color: #0778d6;
	font-weight: 700;
	text-decoration: underline;
}

#partners_module.homepage_contentbox.displaymode_list .cms_title a:hover {
	color: #1b2f63;
	cursor: pointer;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item .cms_metadata1.cms_title .cms_content {
	color: #373a3d;
	font-size: 1rem;
	line-height: 1.5rem;
	display: block;
}

#partners_module.homepage-content-box.partners-carousel {
	margin-bottom: 20px;
}

#partners_module.homepage-content-box.partners-carousel .partners-arrow {
	border-color: #333;
}

/* posts */
.home-section .posts-collection .posts-node {
	padding: 0px;
	margin: 0px 0px 7px 0px;
}

.posts-collection.homepage-contentbox.article-items {
	margin-bottom: 15px;
}

.posts-homepage-block.button.primary {
	display: none;
}

.posts-collection.homepage-contentbox.article-items .article {
	padding-left: 0;
	padding-right: 0;
}

.posts-collection.homepage-contentbox.article-items .article-date {
	margin: 0;
}

.cb-para p.article-abstract {
	max-width: 100%;
	margin: 0;
}

@media (max-width: 991px) {
	.posts-collection.homepage-contentbox.article-items .article-image-link,
	.posts-collection.homepage-contentbox.article-items .article-content {
		float: none;
		width: 100%;
		padding-left: 0;
	}

	.game-4-wrapper .collection.posts-collection.homepage-contentbox.article-items {
		padding-left: 30px;
		padding-right: 30px;
	}
}

/* submission forms */
#submissionforms_module.homepage_contentbox {
	color: #373a3d;
	font-family: inherit;
}

#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox + script + .home-content-box-learn-more {
	display: none;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/*ecommerce*/
#products_module.homepage_contentbox.displaymode_list .metadata1.name,
#products_module.homepage_contentbox.displaymode_category_list .links {
	margin-top: 10px;
}

#products_module.featured_list .metadata1.cms_title h3 {
	margin-top: 0;
	margin-bottom: 0;
}

#products_module.homepage_contentbox.displaymode_list .metadata1.name a,
#products_module.featured_list .metadata1.cms_title h3 a,
#products_module.homepage_contentbox.displaymode_category_list .links a {
	font-size: 1.125rem;
	line-height: 1.625rem;
	color: #0778d6;
	font-weight: 700;
	text-decoration: underline;
}

#products_module.homepage_contentbox.displaymode_list .metadata1.name a:hover,
#products_module.featured_list .metadata1.cms_title h3 a:hover,
#products_module.homepage_contentbox.displaymode_category_list .links a:hover {
	color: #1b2f63;
	cursor: pointer;
}

#products_module.homepage_contentbox.displaymode_list .cms_content p {
	color: #373a3d;
	font-size: 1rem;
	line-height: 1.5rem;
	display: block;
	margin-top: 0;
	margin-bottom: 0;
}

#products_module.homepage_contentbox.displaymode_list .cms_content a:last-of-type {
	display: none;
}
